import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

if (typeof window.sentry_config !== 'undefined') {
    const integrations = [];

    if (window.sentry_config.performance) {
        integrations.push(new BrowserTracing());
    }

    // Init Sentry:
    Sentry.init({
        dsn: 'https://54ba3e752902415aae4ade62e38661be@sentry.keuze.nl/2',
        logErrors: true,
        environment: window.sentry_config.environment,
        release: window.sentry_config.release,
        integrations,
        tracesSampleRate: window.sentry_config.perf_sr,
    });
}
